import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './navbar.css'

const Navbar = () => {
    const [openHamburger, setOpenHamburger] = useState(false)
    return (
        <div className='navbar'>
            <div className='imageContainer'>
                <img src="/assets/images/logo.webp" alt="logo" />
            </div>
            <div className='navbarContainer'>
                <NavLink className={'nav'} to='/about'>About</NavLink>
                <NavLink className={'nav'} to='/contact'>Contact</NavLink>
                <NavLink className={'nav'} to='/contact'>Projects</NavLink>
                <NavLink className={'nav'} to='/contact'>Services</NavLink>
            </div>
            {
                !openHamburger && (
                    <div className={'hamburger'} onClick={() => setOpenHamburger(true)} >
                        <div className='hamburger-line' />
                        <div className='hamburger-line' />
                        <div className='hamburger-line' />
                    </div>
                )

            }

            <div className={openHamburger ? ' hamburger-container container-active' : 'hamburger-container'} onClick={() => setOpenHamburger(false)}>
                <div className='cross-icon-container' >
                    <div className='cross-icon1'>
                    </div>
                    <div className='cross-icon2'>
                    </div>
                </div>
                <ul className={openHamburger ? 'hamburger-menu menu-active' : 'hamburger-menu'} >
                    <li>About</li>
                    <li>Contact</li>
                    <li>Projects</li>
                    <li>Services</li>
                </ul>
            </div>
        </div>

    )
}

export default Navbar