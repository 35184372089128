import React from 'react'
import Navbar from './Navbar/Navbar'
import './layout.css'
const Layout = ({ children }) => {
    return (
        <div className='layoutContainer'>

            <Navbar />
            <main>{children}</main>

        </div>
    )
}

export default Layout