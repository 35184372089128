import './home.css'
import React from 'react'
import Typed from 'react-typed';
import Layout from '../../layout/Layout'
const Home = () => {

    return (
        <Layout>
            <div className='header'>
                <h3>React & React Native Developer</h3>
                <p>I design and code beautifully simple things, and I love what I do.</p>
                <Typed
                    className='typed'
                    strings={['Hello, I am Prashanta Baral']}
                    typeSpeed={40}
                    loop
                />
                <div className='hancyContainer' >
                    {/* <img src="/assets/images/cloudComment.png" alt="cloudComment" /> */}
                    {/* <h3 className='name'>Prashanta Baral</h3> */}
                </div>
            </div>

        </Layout>
    )
}

export default Home
